module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/apu.png","icons":[{"src":"src/images/apu.png","sizes":"192x192","type":"image/png"},{"src":"src/images/apu.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"name":"MemeCoffer","short_name":"MemeCoffer","description":"Create memes with ease using MemeCoffer!","start_url":"/","background_color":"#ff7aac","theme_color":"#fff","display":"standalone","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84fc5f0777a4c3ea361c7032fc2f0fc9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
